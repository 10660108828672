import _ from "lodash";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { connect, useSelector } from "react-redux";
import WebApp from "@twa-dev/sdk";
import { customerApi } from "reducers/api";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
// import JSConfetti from "js-confetti";
import Confetti from "react-dom-confetti";

import { withTranslate } from "react-redux-multilingual";
import LocalesValues, { getLocaledValue } from "components/standart/LocalesValues";
import Col3Container from "components/standart/col3Container";
import { LoadingIcon } from "components/standart";
import { ConfettiBlock } from "../confetti";

const { api, session } = customerApi.actions;

const PremiumBlock = (props) => {
  const { locales, translate, asLock } = props;

  const { account } = props.sessionStore;
  const { settings } = props.env.www;
  const { premiums, currencies } = props.customerEnv;

  const [currenciesById, setCurrenciesId] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [currentPremium, setCurrentPremium] = useState(null);
  const [currentPrice, setCurrentPrice] = useState(null);
  const [currentCurrency, setCurrentCurrency] = useState(null);
  const [successBuy, setSuccessBuy] = useState(null);

  const toggleModal = () => setOpenModal(!openModal);

  const canvasId = "confetti-canvas";

  const startConfetti = useCallback(() => {
    // const canvas = document.getElementById(canvasId);
    // const jsConfetti = new JSConfetti(canvas);
    // jsConfetti.addConfetti({ confettiColors });
    // jsConfetti.clearCanvas();
    // console.log("jsConfetti is", jsConfetti);
  }, []);

  useEffect(() => {
    if (!openModal) {
      setCurrentPremium(null);
      setCurrentPrice(null);
      setCurrentCurrency(null);
      setSuccessBuy(null);
    } else {
      setTimeout(() => {
        startConfetti();
      }, 100);
    }
  }, [openModal]);

  const openPremiumInfo = (premium, price, currency) => () => {
    setCurrentPremium(premium);
    setCurrentPrice(price);
    setCurrentCurrency(currency);
    setOpenModal(true);
  };

  useEffect(() => {
    setCurrenciesId(_.keyBy(currencies, "_id"));
  }, [currencies]);

  const startPaymentHandler = (premiumId, currencyId) => () => {
    //
    const body = { premiumId, currencyId, localeId: locales.data._id };
    props.requestPremium(
      { body },
      {
        onRequest() {
          setLoading(true);
        },
        onSuccess({ id, url }) {
          setLoading(false);
          if (url) {
            WebApp.openInvoice(url, (status) => {
              if (status === "paid") {
                setSuccessBuy(true);
              }
            });
          } else if (id) {
            // WebApp.shareMessage(id);
          }
          //
        },
        onFailure() {
          setLoading(false);
          //
        },
      },
    );
  };

  const completePremiumHandler = () => {
    props.getUser(
      {},
      {
        onRequest() {
          setLoading(true);
        },
        onSuccess() {
          setLoading(false);
          setOpenModal(false);
        },
        onFailure() {
          setLoading(false);
        },
      },
    );
  };

  return (
    <>
      {_.map(premiums, (premium) => {
        return (
          <div key={premium._id} className="text-center flex-fill my-auto">
            {_.map(premium.prices, (price) => {
              const currency = currenciesById[price.currencyId];
              return currency ? (
                asLock ? (
                  <button
                    key={`${premium._id}-${currency._id}`}
                    className="btn btn-solid border-0 bg-none"
                    // style={{ background: "none !important" }}
                    // onClick={startPaymentHandler(premium._id, currency._id)}
                    onClick={openPremiumInfo(premium, price, currency)}
                  >
                    <font className="standart-header">
                      <i className="fa fa-lock mr-2" />
                      <LocalesValues
                        values={settings.premiumLockButtonText}
                        defaultValue={translate("premium_lock")}
                        isHtml={false}
                      />
                    </font>
                  </button>
                ) : (
                  <button
                    key={`${premium._id}-${currency._id}`}
                    className="btn btn-solid"
                    // onClick={startPaymentHandler(premium._id, currency._id)}
                    onClick={openPremiumInfo(premium, price, currency)}
                  >
                    <i className={`fa ${props.buttonIcon || "fa-star"} mr-2`} />
                    {props.buttonText || (
                      <LocalesValues
                        values={settings.premiumButtonText}
                        defaultValue={translate("premium_buy")}
                        isHtml={false}
                      />
                    )}
                  </button>
                )
              ) : null;
            })}
          </div>
        );
      })}

      {openModal && (
        <Modal
          centered
          wrapClassName="fullscreen-modal"
          isOpen={openModal}
          size="md"
          toggle={successBuy ? completePremiumHandler : toggleModal}
        >
          <ModalHeader toggle={successBuy ? completePremiumHandler : toggleModal}></ModalHeader>
          <ModalBody className="py-0">
            <Col3Container>
              <div className="text-center position-relative">
                <ConfettiBlock active={successBuy} />

                {successBuy ? (
                  <div>
                    <div>
                      <div className="large-header mb-3">
                        <LocalesValues values={settings.premiumSuccessTitle} isHtml={false} />
                      </div>
                      <div className="html-content mb-4">
                        <LocalesValues values={settings.premiumSuccessText} isHtml={false} />
                      </div>

                      <div className="pt-2" />

                      <button className="btn btn-solid" onClick={completePremiumHandler}>
                        {isLoading ? (
                          <LoadingIcon className="mr-2" />
                        ) : (
                          <i className="fa fa-check mr-2" />
                        )}
                        <LocalesValues values={settings.premiumSuccessButton} isHtml={false} />
                      </button>
                    </div>
                    {/* <div className="position-absolute w-100 h-100"> */}
                    {/* </div> */}
                  </div>
                ) : (
                  <div>
                    <div className="large-header mb-3">
                      <LocalesValues values={currentPremium.localesName} isHtml={false} />
                    </div>
                    <div className="standart-header mb-4">
                      <LocalesValues values={currentPremium.localesText} isHtml={false} />
                    </div>

                    <div className="pt-2" />
                    <button
                      className="btn btn-solid"
                      onClick={startPaymentHandler(currentPremium._id, currentCurrency._id)}
                    >
                      {isLoading ? (
                        <LoadingIcon className="mr-2" />
                      ) : (
                        <i className="fa fa-check mr-2" />
                      )}
                      <LocalesValues values={currentPremium.localesButton} isHtml={false} />
                    </button>
                  </div>
                )}
              </div>
            </Col3Container>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  env: state.frontend.env,
  customerEnv: state.customer.env,
  sessionStore: state.customer.session,
  locales: state.locales,
});

export default connect(mapStateToProps, {
  ...session.actions,
  requestPremium: api.post("/account/webapp/premium/request"),
  confirmPremium: api.post("/account/webapp/premium/confirm"),
})(withTranslate(PremiumBlock));
