import React from "react";

import { Route, Switch } from "react-router-dom";
// import Sitemap from "./sitemap";

import Header from "components/common/headers/header";
import Footer from "components/common/footers/footer";

import Layout from "./views/layout";

import Index from "./views";
import Account from "./views/account";
import SharedContent from "./views/share";
import GoPage from "./views/go";
import Demo from "./views/demo";
import WebApp from "./views/webapp";

import SettingsButton from "./views/account/settings/button";

// import Partners from "./views/partners";
// import PartnersLanding from "./views/partners/landing";

import Content from "./views/content";
import Feedback from "./views/feedback";

// import Feedback from "./views/feedback";

const RouteElem = (props) => {
  return (
    <Layout>
      <Header />
      {props.children}
      <Footer />
    </Layout>
  );
};


const Routes = () => {
  return (
    <Switch>
      <Route
        exact
        path={`/`}
        component={() => (
          <RouteElem>
            <Index />
          </RouteElem>
        )}
      />
      <Route
        exact
        path={`/account`}
        component={() => (
          <RouteElem>
            <Account />
          </RouteElem>
        )}
      />
      <Route
        exact
        path={`/demo`}
        component={() => (
          <RouteElem>
            <Demo />
          </RouteElem>
        )}
      />
      <Route
        exact
        path="/offer"
        component={() => (
          <RouteElem>
            <Content />
          </RouteElem>
        )}
      />
      <Route
        exact
        path="/share/:segment/:id"
        component={() => (
          <RouteElem>
            <SharedContent />
          </RouteElem>
        )}
      />
      <Route
        exact
        path="/go"
        component={() => (
          <RouteElem>
            <GoPage />
          </RouteElem>
        )}
      />
      <Route
        exact
        path="/feedback"
        component={() => (
          <RouteElem>
            <Feedback />
          </RouteElem>
        )}
      />
      <Route
        // exact
        path="/app"
        component={() => (
          <Layout>
            <Header webApp={true} />
            <WebApp />
            <Footer webApp={true} />
          </Layout>
        )}
      />
    </Switch>
  );
};

export default Routes;
