import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import WebApp from "@twa-dev/sdk";

export const WebAppUserpic = (props) => {
  const { size, noBorder } = props;
  const { sessionStore, noPremium } = props;
  return (
    <img
      className="rounded-circle"
      src={props.src || WebApp.initDataUnsafe.user.photo_url}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        border: noBorder
          ? null
          : sessionStore.premium && !noPremium
          ? "2px solid gold"
          : "0px solid silver",
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  sessionStore: state.customer.session,
});

export default connect(mapStateToProps, {})(WebAppUserpic);
