import _ from "lodash";
import React, { useState, useEffect, useRef } from "react";
import WebAppUserpic from "components/standart/webapp-userpic";

import { useTranslate } from "react-redux-multilingual";
import LocalesValues from "components/standart/LocalesValues";
import GiphyImage from "components/standart/giphyImage";

export const openFriend = "openFriend";

export const FriendZodiac = (friend) => {
  const translate = useTranslate();
  const { withPhoto } = friend;
  const [name] = _.split(friend.name, " ");
  return (
    <div className="d-flex align-items-center">
      {withPhoto && friend.imageUrl ? (
        <div className="align-self-center mr-2">
          <WebAppUserpic src={friend.imageUrl} size={24} noPremium={true} />
        </div>
      ) : null}
      <div className="align-self-center text-nowrap">{name}</div>
      <div className="align-self-center ml-1 hashTag">
        {`#${translate(`zodiac_${friend.astroSign}`).toLowerCase()}`}{" "}
      </div>
    </div>
  );
};

export const FriendsStateIcon = (gradientInfo) => {
  return (
    <span className="hashTag">
      {gradientInfo?.icon} <LocalesValues values={gradientInfo.localesName} />
    </span>
  );
};

export const FriendsNamesInfo = (props) => {
  const translate = useTranslate();
  const { customer, friend, customerSign } = props;
  return (
    <div className="d-flex flex-column">
      <div className="mx-auto">
        <FriendZodiac {...customer} withPhoto={true} />
      </div>
      <div className="my-1"></div>
      <div className="mx-auto">
        <FriendZodiac {...friend} withPhoto={true} />
      </div>
    </div>
  );
};

export const FriendsCommonOverlap = (props) => {
  const translate = useTranslate();
  const { overlapInfo } = props;
  return (
    <div>
      <div className="large-header mb-3">{translate("friends_common")}</div>
      <div className="form-group content-html">
        <LocalesValues values={overlapInfo?.localesText} isHtml={true} />
      </div>
    </div>
  );
};

export const FriendsCommonBlock = (props) => {
  const translate = useTranslate();

  const { data, customer, friend, overlapInfo } = props;
  return (
    <>
      <div className="large-header pb-3">{translate("friends_today")}</div>
      {_.size(data?.imageData?.data) ? (
        <div className="mb-3">
          <GiphyImage {...data.imageData} />
        </div>
      ) : null}
      <div className="mb-2">
        <FriendsNamesInfo {...{ customer, friend: friend.anotherCustomer || friend }} />
      </div>
      <div>
        <div className="content-html">
          {data?.gradientText ? (
            <LocalesValues
              values={data?.gradientText.localesText}
              isHtml={true}
              changeValueHandler={props.changeValueHandler}
            />
          ) : null}
        </div>
        <div className="mt-2 hashTag">
          <span>
            <FriendsStateIcon {...data?.gradientInfo} />
          </span>
        </div>
      </div>
    </>
  );
};
