import _ from "lodash";
import React, { useEffect, useState, useRef, useCallback } from "react";

import { connect, useSelector } from "react-redux";
import { useTranslate } from "react-redux-multilingual";
import { LoadingIcon, scrollTop, ShowPager } from "components/standart";
import GiphyImage from "components/standart/giphyImage";
import LoadingModal from "components/standart/loading-modal";
// import ShareButton, { contentId } from "components/standart/share-button";
import WebAppShareButton, { contentId } from "components/standart/webapp-share-button";
import LocalesValues, { getLocaledValue } from "components/standart/LocalesValues";
import Typewriter from "typewriter-effect";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import MemeImage from "components/standart/memeImage";
import ShowToday from "components/standart/showToday";
import nl2br from "react-nl2br";
import WebApp from "@twa-dev/sdk";

import { customerApi } from "reducers/api";

import NextDay from "../data/nextDay";
import PremiumBlock from "../data/premium";
import { FriendZodiac } from "../data/friends/friendsDataElements";
// import TodayScore from "./todayScore";

const { api } = customerApi.actions;
SwiperCore.use([Pagination]);

const AspectsBlock = (props) => {
  const translate = useTranslate();
  const { sessionStore } = props;

  const { locales } = useSelector((state) => state);
  const [dailyContents, setDailyContents] = useState([]);
  const [currentDaily, setCurrentDaily] = useState(null);
  const [noPremium, setNoPremium] = useState(true);
  const [currentDailyText, setCurrentDailyText] = useState("");
  const [swiper, setSwiper] = useState(null);

  const [baseLoaded, setBaseLoaded] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [aspectText, setAspectText] = useState(null);
  const [lastLoaded, setLastLoaded] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const slideChangeHandler = (event) => {
    setCurrentIndex(event.realIndex);
    return true;
  };

  useEffect(() => {
    dailyContents.length && setCurrentDaily(dailyContents[currentIndex]);
  }, [lastLoaded, currentIndex]);

  useEffect(() => {
    setNoPremium(!sessionStore.premium);
    if (currentDaily) {
      const { planet } = currentDaily.aspectCategory;
      const { usedAstroAspect } = currentDaily.aspectDate;
      if (usedAstroAspect) {
        setAspectText(
          translate("moon_aspect_text", {
            aspect: translate(`aspects_pred_${usedAstroAspect}`),
            planet: translate(`planets_tvor_${planet}`),
          }),
        );
      } else {
        setAspectText(translate("moon_aspect_default", { planet: translate(`planets_${planet}`) }));
      }

      const text = getLocaledValue(currentDaily.aspectQuote.localesText, locales.data?._id);
      // const textUp = getLocaledValue(currentDaily.aspectQuote.localesTextUp, locales.data?._id);
      // const textDown = getLocaledValue(currentDaily.aspectQuote.localesTextDown, locales.data?._id);
      setCurrentDailyText(`${text}`);
      // console.log("currentDaily", currentDaily);
      if (!currentDaily.aspectQuote.memeImageUrl) {
        loadContentElement(currentDaily._id);
      }
    }
  }, [currentDaily, sessionStore.premium]);

  const loadContent = () => {
    props.getDailyContent(
      {},
      {
        onSuccess(body) {
          setDailyContents(body);
          setLastLoaded(new Date());
          setTimeout(() => {
            swiper && swiper.slideTo(0);
          }, 10);

          // setContent(body);
          // setIsLoaded(false);
        },
        onRequest() {
          // setIsLoaded(true);
        },
      },
    );
  };

  const updateSwiperSize = useCallback(() => {
    swiper.updateAutoHeight(200);
  }, [swiper]);

  const loadContentElement = useCallback(
    (contentId) => {
      props.getDailyContentElement(
        { query: { contentId, localeId: locales.data?._id } },
        {
          onSuccess(body) {
            // заменим содержимое в списке по факту получения id картинки
            setDailyContents(
              _.map(dailyContents, (content) => (content._id === body._id ? body : content)),
            );
            setTimeout(() => updateSwiperSize(), 500);
          },
          onRequest() {
            // setIsLoaded(true);
          },
        },
      );
    },
    [dailyContents, swiper],
  );

  useEffect(() => {
    loadContent();
  }, []);

  return dailyContents.length ? (
    <div className="text-center">
      <div className="standart-header mb-3">
        <ShowToday />
      </div>
      <div className="mb-3 large-header">{translate("your_horoscope")}</div>
      <div className="position-relative">
        {/* 
        <div
          className="px-1 py-0 rounded standart-header"
          style={{
            position: "absolute",
            zIndex: 10,
            top: 0,
            right: 0,
            backgroundColor: "rgba(0, 0, 0, .38)",
            color: "white",
            fontSize: "0.9rem",
          }}
        >
          {`${currentIndex + 1}/${dailyContents.length + 1}`}
        </div>
         */}

        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          autoHeight={true}
          observer={true}
          observeSlideChildren={true}
          // setWrapperSize={true}
          pagination={false}
          onSwiper={setSwiper}
          onSlideChange={slideChangeHandler}
        >
          {dailyContents.map((dailyContent, index) => {
            const isPremium = false; // dailyContent.aspectCategory.isPaid && !sessionStore.premium;
            return (
              <SwiperSlide key={index}>
                <div className="text-center position-relative">
                  {/* 
                  {dailyContent.imageData ? (
                    <GiphyImage {...dailyContent.imageData} blured={isPremium} />
                  ) : (
                    <div className="giphy-image pt-5">
                      <LoadingIcon className="mt-5" />
                    </div>
                  )}
                  */}

                  {dailyContent.aspectQuote.memeImageUrl ? (
                    <MemeImage url={dailyContent.aspectQuote.memeImageUrl} blured={isPremium} />
                  ) : (
                    <div className="giphy-image pt-5">
                      <LoadingIcon className="mt-5" />
                    </div>
                  )}
                </div>
              </SwiperSlide>
            );
          })}
          {/* 
          <SwiperSlide>
            <div className="giphy-image pt-5">
              <NextDay
                {...sessionStore}
                isVisible={currentIndex === dailyContents.length}
                className="mt-3"
              />
            </div>
          </SwiperSlide>
          */}
        </Swiper>

        <div className="py-2">
          <ShowPager total={dailyContents.length + 0} current={currentIndex} swiper={swiper} />
        </div>

        {currentDaily && (
          <div>
            <div className="position-relative">
              <div className="d-flex flex-column mb-2">
                <div className="mx-auto">
                  <FriendZodiac {...sessionStore.account} withPhoto={true} />
                </div>
              </div>

              <div className="content-html position-relative">
                {currentIndex > 0 && noPremium ? (
                  <div
                    className="position-absolute w-100 h-100"
                    style={{ zIndex: 99, top: 0, left: 0 }}
                  >
                    <div className="d-flex align-items-stretch h-100 w-100">
                      <PremiumBlock asLock={true} />
                    </div>
                  </div>
                ) : null}

                <div style={{ filter: currentIndex > 0 && noPremium ? "blur(4px)" : "" }}>
                  {currentDaily.aspectQuote.memeImageUrl || true ? (
                    <div>{nl2br(currentDailyText)}</div>
                  ) : (
                    <Typewriter
                      options={{
                        strings: currentDailyText,
                        delay: 5000 / currentDailyText.length,
                        autoStart: true,
                        loop: false,
                      }}
                    />
                  )}
                  <div className="mt-2">{aspectText}</div>
                </div>

                {/* <LocalesValues values={currentDaily.dailyQuote.localesText} isHtml={true} /> */}
                {/* {ReactHtmlParser(currentDaily.dailyQuote.text)} */}
              </div>
              {/* 
              <div className="mt-2 hashTag -text-lowercase">
                {currentDaily.aspectCategory.icon}{" "}
                <LocalesValues
                  values={currentDaily.aspectCategory?.localesName}
                  defaultValue={currentDaily.aspectCategory?.name}
                />
              </div>
              */}
            </div>

            <div className="pt-4">
              <div className="pt-2" />
              <WebAppShareButton share={"aspect"} contentId={currentDaily._id} />
              {/* <ShowShare currentId={currentDaily._id} /> */}
            </div>
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className="text-center p-5">
      <LoadingIcon />
    </div>
  );
};

const mapStateToProps = (state) => ({
  env: state.frontend.env,
  sessionStore: state.customer.session,
});

export default connect(mapStateToProps, {
  getDailyContent: api.get("/account/webapp/aspects"),
  getDailyContentElement: api.get("/account/webapp/aspects/info"),
})(AspectsBlock);
