import React, { useEffect, useState, useRef, useCallback } from "react";
import Confetti from "react-dom-confetti";

export const confettiColors = ["#ff0a54", "#ff477e", "#ff7096", "#ff85a1", "#fbb1bd", "#f9bec7"];

export const confettiConfig = {
  angle: 180,
  spread: 360,
  startVelocity: 50,
  elementCount: 200,
  dragFriction: 0.20,
  duration: 10000,
  stagger: 1,
  width: "15px",
  height: "15px",
  perspective: "1000px",
  colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
};

export const ConfettiBlock = (props) => {
  return <Confetti active={props.active} config={confettiConfig} />;
};
