import React from "react";

import { Route, Switch, Link, useLocation } from "react-router-dom";
// import Sitemap from "./sitemap";
// import Feedback from "./views/feedback";

import WebAppHoroscrope from "./horoscope";
import WebAppFriends from "./friends";
import WebAppShop from "./shop";


export const WebAppLinks = [
  {
    link: "/app",
    component: WebAppHoroscrope,
    title: "app_horoscope",
    icon: "fa-star",
  },
  {
    link: "/app/friends",
    component: WebAppFriends,
    title: "app_friends",
    icon: "fa-user-friends",
  },
  {
    link: "/app/shop",
    component: WebAppShop,
    title: "app_shop",
    icon: "fa-shopping-cart",
  },
];
