import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

const MemeImage = (imageData = {}) => {
  const { url, blured, className, style } = imageData;
  return url ? (
    <img
      src={url}
      className={`meme-image ${className}`}
      style={{
        filter: blured ? "blur(21px)" : "",
        ...style,
      }}
      onLoad={imageData.loadHandler}
    />
  ) : null;
  // return url ? (
  //   <div
  //     className={`meme-image ${className}`}
  //     style={{
  //       backgroundImage: `url("${url}")`,
  //       filter: blured ? "blur(21px)" : "",
  //       ...style,
  //     }}
  //   ></div>
  // ) : null;
};

export default connect(null, {})(MemeImage);
