import _ from "lodash";
import React, { useState, useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import nl2br from "react-nl2br";
import { connect } from "react-redux";

export function getLocaledValue(values, localeId) {
  if (localeId) {
    const [localedValue] = _.filter(values, (value) => value.localeId === localeId);
    if (localedValue) {
      return localedValue.value;
    }
  }
  return "";
}

const LocalesValues = (props) => {
  const { locales, values = [], changeValueHandler, localeId, defaultValue = "", isHtml } = props;
  const [value, setValue] = useState(null);
  useEffect(() => {
    let localedValue;
    if (localeId) {
      localedValue = getLocaledValue(values, localeId);
    } else if (locales.data?._id) {
      localedValue = getLocaledValue(values, locales.data._id);
    }
    if (localedValue) {
      setValue(localedValue);
    } else {
      setValue(defaultValue);
    }
  }, [locales.data, localeId, values]);

  useEffect(() => {
    if (changeValueHandler) changeValueHandler(value);
  }, [value]);

  return <>{isHtml ? ReactHtmlParser(value) : nl2br(value)}</>;
};

const mapStateToProps = (state) => ({
  locales: state.locales,
});

export default connect(mapStateToProps, {})(LocalesValues);
