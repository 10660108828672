import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

const GiphyImage = (imageData = {}) => {
  const { data, blured, className, style } = imageData;
  return _.size(data) ? (
    <div
      className={`giphy-image ${className}`}
      style={{
        backgroundImage: `url("${data.images.downsized.url}")`,
        filter: blured ? "blur(21px)" : "",
        ...style
      }}
    ></div>
  ) : null;
};

export default connect(null, {})(GiphyImage);
