import _ from "lodash";
import {
  SET_NAVI,
  RESET_NAVI,
  SET_HEADER_HEIGHT,
  SET_BOTTOM_HEIGHT,
  SET_FRIENDS_REFRESH,
} from "constants/ActionTypes";

export default (state = {}, action) => {
  _.defaults(state, {
    url: null,
    headerSpaceHeight: 0,
    bottomSpaceHeight: 0,
    friendsRefresh: null,
  });
  switch (action.type) {
    // установить сообщение по умолчанию
    case SET_FRIENDS_REFRESH:
      return {
        ...state,
        friendsRefresh: action.value,
      };
    case SET_NAVI:
      return {
        ...state,
        url: action.url,
      };
    case RESET_NAVI:
      return { ...state, url: null };
    case SET_HEADER_HEIGHT:
      return {
        ...state,
        headerSpaceHeight: action.headerHeight,
      };
    case SET_BOTTOM_HEIGHT:
      return {
        ...state,
        bottomSpaceHeight: action.bottomHeight,
      };
    default:
  }
  return state;
};
