import _ from "lodash";
import React, { useState } from "react";
import { connect } from "react-redux";
import { withTranslate } from "react-redux-multilingual";
import { customerApi, frontendApi } from "reducers/api";

import SettingsModal from "components/standart/settings-modal";

const { api, session } = customerApi.actions;
const { api: frontend } = frontendApi.actions;

const AccountInfo = (props) => {
  const { translate, sessionStore } = props;
  const { account } = sessionStore;

  const [openModal, setOpenModal] = useState(null);

  return (
    <div className="mt-2">
      <div className="form-group">
        <div>
          {account.name || account.surname ? (
            <>
              <>{translate("settings_and_preferences")}</>
              {/* 
              {`${account.name}`}
              {", "}
              {`${moment(account.birthDate).format("DD.MM.YYYY")}`}
              {", "}
              {`${translate(`gender_${account.gender}`).toLowerCase()}`}
              */}
            </>
          ) : (
            <>{translate("settings_no_name")}</>
          )}

          <span className="url ml-2" onClick={() => setOpenModal(!openModal)}>
            <i className="fa fa-edit mr-1" />
            {translate("edit")}
          </span>
        </div>

        <SettingsModal {...{ openModal, setOpenModal }} />
      </div>
    </div>
  );
};
const mapeStateToProps = (state) => ({
  sessionStore: state.customer.session,
  systemEnv: state.customer.env,
});
export default connect(mapeStateToProps, {
  ...session.actions,
})(withTranslate(AccountInfo));
