import _ from "lodash";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { connect } from "react-redux";
import { useTranslate } from "react-redux-multilingual";
import { customerApi } from "reducers/api";
import WebApp from "@twa-dev/sdk";
import LocalesValues, { getLocaledValue } from "components/standart/LocalesValues";

import { LoadingIcon } from "./index";

// import { shareStory, sendData, safeCall } from "@telegram-apps/sdk";

const { api } = customerApi.actions;

export const contentId = "content";
export const friendId = "friend";
export const celebrityId = "celebrity";

export const WebAppShareButton = (props) => {
  const [copied, setCopied] = useState(false);
  const { settings } = props.env.www;

  const { locales, disabled } = props;
  const tooltipTimeout = useRef();
  useEffect(() => {
    if (copied) {
      tooltipTimeout.current = setTimeout(() => setCopied(false), 3000);
    }
  }, [copied]);

  const { contentId, share } = props;
  const translate = useTranslate();
  const [url, setUrl] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [id, setId] = useState(null);
  useEffect(() => {
    // setUrl(`${document.location.origin}/share/${share}/${currentId}?lang=${locales.data.code}`);
    setId(`daily_${contentId}`);
  }, [contentId]);

  const prepareMessageHandler = useCallback(
    (event) => {
      props.prepareShareMessage(
        { body: { share, contentId, localeId: locales.data._id } },
        {
          onSuccess(body) {
            setLoading(false);
            try {
              WebApp.shareMessage(body.id);
            } catch (e) {
              console.log(e);
              window.location.reload();
            }
          },
          onRequest() {
            setLoading(true);
            setError(false);
          },
          onFailure() {
            setLoading(false);
            setError(true);
          },
        },
      );
      // metrikaTargetClick({ ...event });
      // event.preventDefault();
    },
    [share, locales, contentId],
  );

  return (
    <span>
      <button
        id={id}
        className="btn btn-solid telegram-btn"
        target="_blank"
        title={translate("share")}
        data-id="share-daily"
        disabled={disabled || isLoading}
        onClick={prepareMessageHandler}
      >
        {isLoading ? (
          <LoadingIcon className="mr-2" />
        ) : (
          <i className="fab fa-telegram-plane mr-2" />
        )}

        <LocalesValues
          values={settings.shareButtonText}
          defaultValue={translate("share_telegram")}
        />
      </button>
    </span>
  );
};

const mapStateToProps = (state) => ({
  locales: state.locales,
  env: state.frontend.env,
});

export default connect(mapStateToProps, {
  prepareShareMessage: api.post("/account/webapp/share"),
})(WebAppShareButton);
