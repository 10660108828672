import _ from "lodash";

import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { IntlActions, useTranslate } from "react-redux-multilingual";
import { Helmet } from "react-helmet";
import { useRouteMatch, useLocation } from "react-router-dom";
// Custom Components
// import Header from "components/common/headers/header";
// import Footer from "components/common/footers/footer";
import { scrollTop } from "components/standart";
import CookieConsent from "react-cookie-consent-notification";
import { setBottomSpaceHeight, clearWhatsAppMessage, setLocaleData } from "actions";
import { frontendApi, customerApi } from "reducers/api";
import { accessToken } from "reducers/api/customer";

import { history } from "store";
import qs from "query-string";
import { FBPixel, VKPixel } from "components/pixels";

import "components/common/index.scss";
// swiper bundle styles
import "swiper/swiper-bundle.min.css";
// import "swiper/components/pagination/pagination.scss";
import { YMInit } from "services";

const { SITE_URL } = process.env;
const { env } = frontendApi.actions;
const { session, env: systemEnv, assistant } = customerApi.actions;

FBPixel.pageView();

const App = (props) => {
  const translate = useTranslate();
  const { locales, customerEnv } = props;
  const { url, path } = useRouteMatch();
  const location = useLocation();

  useEffect(() => {
    const autoAuth = "setAuthToken";
    if (location.hash.search(autoAuth) >= 0) {
      const { [autoAuth]: authToken } = qs.parse(location.hash);
      // console.log({ location, authToken });
      localStorage.setItem(accessToken, `Bearer ${authToken}`);
      history.replace(`${location.pathname}${location.search}`);
      // history.replaceState()
    }
  }, [location.hash]);

  useEffect(() => {
    if (locales.data?._id) {
      props.www({ query: { localeId: locales.data._id } });
    }
  }, [locales.data]);

  useEffect(() => {
    const { lang } = qs.parse(location.search);
    let existsLocale;
    if (locales.data?._id) {
      [existsLocale] = _.filter(customerEnv.locales, (locale) => locale._id === locales.data?._id);
    }
    if (_.size(customerEnv.locales) && (!existsLocale || lang || !locales.data?._id)) {
      const userLang = lang || navigator.language || navigator.userLanguage;
      const [code] = _.split(userLang, "-");
      const [bestLocale] = _.filter(customerEnv.locales, (locale) => locale.code === code);
      const [defaultLocale] = _.filter(customerEnv.locales, (locale) => locale.isDefault);
      const locale = bestLocale || defaultLocale;
      if (locale) {
        props.setLocaleData(locale);
        props.setLocale(locale.code);
      }
    }
  }, [customerEnv]);

  useEffect(() => {
    scrollTop();
    props.system();
    // props.payments();
    // props.location();
    props.clearWhatsAppMessage();
    props.setBottomSpaceHeight(0);

    if (localStorage.getItem(accessToken)) {
      props.getUser();
    }

    setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 0);
  }, []);

  const checkStatus = (status) => {
    if (status) {
      // To set a cookies
    }
  };

  return props.env?.www && props.env.www.settings ? (
    <>
      <Helmet>
        <title>{translate("sitename")}</title>
        <meta property="og:sitename" content={translate("sitename")} />
        <meta property="og:site_name" content={translate("sitename")} />
      </Helmet>

      {YMInit()}
      {/* <Header /> */}
      {props.children}
      {/* <Footer /> */}

      {/* 
      <CookieConsent
        background={"#000000"}
        bottomPosition={true}
        buttonText={translate("cookie_button")}
        buttonClassName="btn btn-solid white-btn"
        // buttonBackground={"#fff"}
        // buttonColor={"#000000"}
        // buttonFontSize={16}
        color={"#fff"}
        consentFunction={checkStatus}
        padding={20}
      >
        {translate("cookie_notice")}
      </CookieConsent>
       */}
    </>
  ) : null;
};

const mapStateToProps = (state) => ({
  customerEnv: state.customer.env,
  env: state.frontend.env,
  locales: state.locales,
});

export default connect(mapStateToProps, {
  ...env.actions,
  ...systemEnv.actions,
  ...session.actions,
  setLocaleData,
  setLocale: IntlActions.setLocale,
  clearWhatsAppMessage,
  setBottomSpaceHeight,
})(App);
