import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Alert } from "reactstrap";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { withTranslate } from "react-redux-multilingual";
import { customerApi, frontendApi } from "reducers/api";
import LocalesValues from "components/standart/LocalesValues";
import BirthDateCalendar from "components/standart/BirthDateCalendar";
import BottomNaviSpacing from "components/standart/bottomNaviSpacing";
import { LoadingIcon } from "components/standart";

import WebAppUserpic from "components/standart/webapp-userpic";

const { api, session } = customerApi.actions;
const { api: frontend } = frontendApi.actions;

const SettingsModal = (props) => {
  const { openModal, setOpenModal } = props;
  const { translate, sessionStore, systemEnv } = props;
  const { account, items } = sessionStore;
  const { settings = {} } = props.env.www;

  const [preferencesList, setPreferencesList] = useState([]);
  const [preferencesId, setPreferencesId] = useState([]);
  const [aspectsCategoriesId, setAspectsCategoriesId] = useState([]);
  const [error, setError] = useState(null);
  const [isDefined, setDefined] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [name, setName] = useState(null);
  const [gender, setGender] = useState(null);
  const [disabled, setDisabled] = useState(null);
  const [birthDate, setBirthDate] = useState(null);

  const changeName = ({ target }) => setName(target.value);
  // const changeBirthDate = ({ target }) => setBirthDate(target.value);

  useEffect(() => {
    setDisabled(!(name && birthDate && (props.webApp || gender)));
  }, [name, gender, birthDate, aspectsCategoriesId]);

  const save = () => {
    props.saveSettings(
      { body: { name, gender, birthDate, preferencesId, aspectsCategoriesId } },
      {
        onRequest() {
          setError(null);
          setLoading(true);
        },
        onFailure({ message }) {
          setError(message);
          setLoading(false);
        },
        onSuccess() {
          setLoading(false);
          props.getUser(
            {},
            {
              onSuccess() {
                setOpenModal(false);
              },
            },
          );
        },
      },
    );
  };

  const toggleModal = () => setOpenModal(!openModal);

  const togglePreference = (preferenceId) => () => {
    if (preferencesId.includes(preferenceId)) {
      setPreferencesId(preferencesId.filter((preference) => preference !== preferenceId));
    } else {
      setPreferencesId([...preferencesId, preferenceId]);
    }
  };

  const toggleQuoteCategory = (categoryId) => () => {
    if (aspectsCategoriesId.includes(categoryId)) {
      setAspectsCategoriesId(aspectsCategoriesId.filter((category) => category !== categoryId));
    } else {
      setAspectsCategoriesId([...aspectsCategoriesId, categoryId]);
    }
  };

  const setDefaultsData = useCallback(() => {
    setName(account.name);
    setGender(account.gender);
    if (account.birthDate) {
      setBirthDate(moment(account.birthDate).format("YYYY-MM-DD"));
    }
    setPreferencesId(account.preferencesId);
    setAspectsCategoriesId(account.aspectsCategoriesId);
    setTimeout(() => setDefined(true), 10);
  }, [account]);

  useEffect(() => {
    setDefaultsData();
  }, [account]);

  useEffect(() => {
    if (openModal) {
      // setDefaultsData();
    }
  }, [openModal]);

  useEffect(() => {
    if (!openModal && isDefined && disabled) {
      setOpenModal(disabled);
    }
  }, [account, isDefined, disabled, items, openModal]);

  const safeCloseModal = () => {
    setDefaultsData();
    setTimeout(() => toggleModal(), 10);
  };

  return (
    <>
      {openModal && (
        <Modal
          centered
          wrapClassName="fullscreen-modal"
          isOpen={openModal}
          size="md"
          toggle={account.birthDate ? safeCloseModal : null}
        >
          <ModalHeader
            toggle={account.birthDate ? safeCloseModal : null}
            className="py-0"
            // tag="div"
            // className={`position-relative align-items-center pb-0 ${
            //   account.birthDate ? "info-modal-full" : "info-modal-empty"
            // }`}
          >
            {/* {translate(account.name ? "settings_info_edit" : "settings_info_edit")} */}
          </ModalHeader>
          <ModalBody>
            {props.webApp ? (
              <>
                {account.astroSign ? null : (
                  <div className="text-center pb-5">
                    <div className="large-header mb-3">
                      <LocalesValues values={settings.settingsIntroTitle} defaultValue={""} />
                    </div>
                    <LocalesValues values={settings.settingsIntroText} defaultValue={""} />
                  </div>
                )}
                <div className={`text-center pb-2`}>
                  <WebAppUserpic size={80} />
                  {sessionStore.premium ? (
                    <div className="pt-2" style={{ color: "gold" }}>
                      {translate("your_premium", {
                        date: moment(sessionStore.premium.expiredAt).format(
                          translate("dateFormat"),
                        ),
                      })}
                    </div>
                  ) : null}
                </div>
              </>
            ) : null}

            <div className="row">
              <div className="col-lg-3 col-sm-hidden" />
              <div className="col">
                <div className="text-center">
                  {error && (
                    <Alert className="alert-danger mx-auto" toggle={() => setError(null)}>
                      {translate(error) || error}
                    </Alert>
                  )}

                  <div className="form-group pb-2">
                    <label className="large-header mb-3">{translate("name")}</label>
                    <input
                      type="text"
                      className="form-control text-center"
                      value={name}
                      onChange={changeName}
                    />
                  </div>

                  <div className="form-group pt-3 pb-2">
                    <label className="large-header mb-3">{translate("birthDate")}</label>
                    <BirthDateCalendar {...{ birthDate, setBirthDate }} />
                  </div>

                  {props.webApp ? null : (
                    <div className="form-group pt-3 pb-2">
                      <label className="large-header mb-3">{translate("your_gender")}</label>
                      <div>
                        {_.map(systemEnv.genders, (_gender, index) => {
                          const isActive = gender === _gender;
                          const activeClass = isActive ? "active" : "default";
                          return (
                            <span
                              key={index}
                              className={`btn py-2 px-3 mx-3 rounded button-selector ${activeClass}`}
                              onClick={() => setGender(_gender)}
                            >
                              {/* {translate(`gender_${_gender}_icon`)}  */}
                              {translate(`gender_${_gender}`)}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  )}

                  {/* 
                    <div className="form-group mt-5">
                      <label className="large-header mb-3">{translate("your_preferences")}</label>
                      <div>
                        {preferencesList.map((preference, index) => {
                          const isActive = preferencesId.includes(preference._id);
                          const activeClass = isActive ? "btn-dark" : "border-grey";
                          return (
                            <span
                              key={index}
                              className={`btn mr-2 mb-2 rounded ${activeClass}`}
                              onClick={togglePreference(preference._id)}
                            >
                              <LocalesValues
                                values={preference.localesName}
                                defaultValue={preference.name}
                              />
                            </span>
                          );
                        })}
                      </div>
                    </div>
                     */}
                  {/* убраны интересы */}
                  {false && props.webApp ? (
                    <div className="form-group pt-3">
                      <label className="large-header mb-3">{translate("your_interests")}</label>
                      <div>
                        {_.map(systemEnv.aspectsCategories, (category, index) => {
                          const isActive = aspectsCategoriesId.includes(category._id);
                          const activeClass = isActive ? "btn-dark" : "border-grey";
                          return (
                            <span
                              key={category._id}
                              className={`btn mr-2 mb-2 rounded ${activeClass}`}
                              onClick={toggleQuoteCategory(category._id)}
                            >
                              {category.icon}{" "}
                              <LocalesValues
                                values={category.localesName}
                                defaultValue={category.name}
                              />
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  ) : null}

                  <div className="form-group text-center mt-5">
                    <button
                      type="button"
                      className="btn btn-solid"
                      disabled={disabled}
                      onClick={save}
                    >
                      {isLoading ? (
                        <LoadingIcon className="mr-1" />
                      ) : (
                        <i className="fa fa-check mr-2" />
                      )}
                      {translate("save")}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-hidden" />
            </div>
            <BottomNaviSpacing />
          </ModalBody>
        </Modal>
      )}
    </>
  );
};
const mapeStateToProps = (state) => ({
  sessionStore: state.customer.session,
  systemEnv: state.customer.env,
  env: state.frontend.env,
});
export default connect(mapeStateToProps, {
  ...session.actions,
  saveSettings: api.post("/account"),
  getPreferences: frontend.get("/preferences"),
})(withTranslate(SettingsModal));
