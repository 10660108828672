import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router, Switch, Route } from "react-router-dom";

import { IntlProvider } from "react-redux-multilingual";
// import "bootstrap/dist/css/bootstrap.min.css";
import "react-image-lightbox/style.css";

import "./index.scss";
import "pretty-checkbox/src/pretty-checkbox.scss";
import "react-datetime/css/react-datetime.css";

import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

// Import custom components
import store, { history } from "store";
import translations from "./constants/translations";

// import Layout from "./views/layout";
import Routes from "./routes";

import { GTMInit } from "services";

GTMInit();

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Root = (props) => {
  return (
    <Provider store={store}>
      {/* <PersistGate loading={null} persistor={persistor}> */}
      <IntlProvider translations={translations} locale="ru">
        <Router {...{ history }}>
          <Routes />
        </Router>
      </IntlProvider>
      {/* </PersistGate> */}
    </Provider>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));
