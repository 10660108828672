import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import { useTranslate } from "react-redux-multilingual";

import { WebAppLinks } from "../links";

const ButtonsMenu = (props) => {
  const translate = useTranslate();

  const location = useLocation();

  const defaultClass = `col pt-2`;
  const isActiveClass = (url) => (url === location.pathname ? "fas" : "far");
  const isActive = (url) => url === location.pathname;
  return (
    <div className="button-menu pb-4">
      <div style={{ height: "1px", backgroundColor: "#fff", opacity: "10%" }}></div>
      <div className="row px-3 pt-2 pb-2">
        {WebAppLinks.map((value, index) => {
          const activeClass = isActive(value.link) ? "active" : "default";
          return (
            <Link
              key={index}
              className={`${defaultClass} `}
              to={value.link}
              style={{ position: "relative", textDecoration: "none" }}
            >
              <i
                className={`${isActiveClass(value.link)} ${value.icon} menu-icon ${activeClass}`}
              />
              <div>
                {/* <small className="text-secondary">{translate("bottom_menu_playlist")}</small> */}
                <small className={`menu-text ${activeClass}`}>{translate(value.title)}</small>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  navi: state.navi,
});

export default connect(mapStateToProps, null)(ButtonsMenu);
