import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withTranslate } from "react-redux-multilingual";
import BirthDateCalendar from "components/standart/BirthDateCalendar";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { customerApi } from "reducers/api";
import { LoadingIcon } from "components/standart";
import Col3Container from "components/standart/col3Container";
import LocalesValues from "components/standart/LocalesValues";
import WebApp from "@twa-dev/sdk";

const { api, session } = customerApi.actions;

const InviteButton = (props) => {
  const { translate, locales } = props;
  const { settings } = props.env.www;

  const [loading, setLoading] = useState(false);

  // отправить инвайт сообщением
  const sendInvite = (messageId, inviteId) => {
    WebApp.shareMessage(messageId, (isSent) => {
      if (isSent) {
        props.sendInvite({ body: { inviteId } });
      }
    });
  };

  const requestInvite = () => {
    props.createInvite(
      { body: { localeId: locales.data._id } },
      {
        onSuccess({ id, inviteId }) {
          sendInvite(id, inviteId);
          setLoading(false);
        },
        onFailure() {
          setLoading(false);
        },
        onRequest() {
          setLoading(true);
        },
      },
    );
  };

  return (
    <div>
      <button type="button" className="btn btn-solid telegram-btn" onClick={requestInvite}>
        {loading ? (
          <LoadingIcon className="mr-2" />
        ) : (
          <i className="fa fa-user-plus mr-2" />
        )}
        <LocalesValues
          values={settings.inviteAction?.actionButtonText}
          isHtml={false}
          default={translate("friend_invite")}
        />
      </button>
    </div>
  );
};

const mapeStateToProps = (state) => ({
  systemEnv: state.customer.env,
  env: state.frontend.env,
  sessionStore: state.customer.session,
  locales: state.locales,
});

export default connect(mapeStateToProps, {
  ...session.actions,
  createInvite: api.post("/account/webapp/invites/create"),
  sendInvite: api.post("/account/webapp/invites/send"),
})(withTranslate(InviteButton));
