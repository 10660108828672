import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslate } from "react-redux-multilingual";
import { WebAppUserpic } from "components/standart/webapp-userpic";

export const InviteUserpics = (props) => {
  const { account } = props;
  const translate = useTranslate();
  const [name] = _.split(account.name, " ");
  return (
    <div className="">
      <div>
        <WebAppUserpic src={account.imageUrl} size={80} noBorder={true} />
      </div>

      <div className="my-2"></div>

      <div className="d-flex flex-column">
        <div className="flex-grow-1 text-truncate" style={{ minWidth: 0 }}>
          {name}
        </div>
        <div className="-mt-1 hashTag">
          {`#${translate(`zodiac_${account.astroSign}`).toLowerCase()}`}
        </div>
      </div>
    </div>
  );
};

export const UsersTogetherPics = (props) => {
  const { account1, account2 } = props;
  return (
    <div className="d-flex align-items-center justify-content-around">
      <div className="pl-4">
        <InviteUserpics account={account1} />
      </div>
      <div className="large-header hashTag pb-5">
        <i className="fal fa-times" />
      </div>
      <div className="pr-4">
        <InviteUserpics account={account2} />
      </div>
    </div>
  );
};
