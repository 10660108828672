import _ from "lodash";
import React, { useState } from "react";
import { connect } from "react-redux";
import { FriendsBlock } from "../data/friends";

const WebAppFriends = (props) => {
  return (
    <div className="mb-5">
      <FriendsBlock/>
    </div>
  );
};

const mapStateToProps = (state) => ({
  env: state.frontend.env,
  sessionStore: state.customer.session,
});

export default connect(mapStateToProps, {})(WebAppFriends);
