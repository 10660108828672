import _ from "lodash";
import React, { useState } from "react";
import { connect } from "react-redux";
import AspectsBlock from "./aspectsBlock";

const WebAppHoroscope = (props) => {
  return (
    <div className="mb-5">
      <AspectsBlock />
    </div>
  );
};

const mapStateToProps = (state) => ({
  env: state.frontend.env,
  sessionStore: state.customer.session,
});

export default connect(mapStateToProps, {})(WebAppHoroscope);
