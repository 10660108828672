import React from "react";
import { connect } from "react-redux";

const BottomNaviSpacing = (props) => {
  const { navi } = props;
  return (
    <div
      style={{
        marginBottom: `${navi.bottomSpaceHeight}px`,
      }}
    ></div>
  );
};

const mapeStateToProps = (state) => ({
  navi: state.navi,
});

export default connect(mapeStateToProps, {})(BottomNaviSpacing);
