import React from "react";
import { Link } from "react-router-dom";

function LogoImage(props) {
  return (
    <>
      <Link
        to={props.webApp ? "/app" : "/"}
        className="d-block -float-left my-auto"
        style={{ textDecoration: "none" }}
      >
        {/* <img src={`/logo.png`} alt="" className="img-fluid" style={{ height: "40px" }} /> */}
        <div className="header-logo">
          <div style={{ fontSize: "1.3em", fontFamily: "Arial Black", fontWeight: "bold" }}>
            {props.webApp ? "astromeme" : "astrogotchi"}
          </div>
        </div>
      </Link>
      <div className="clearfix" />
    </>
  );
}

export default LogoImage;
